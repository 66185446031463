@mixin transition {
	-webkit-transition: all 0.6s ease;
	-moz-transition: all 0.6s ease;
	-o-transition: all 0.6s ease;
	-ms-transition: all 0.6s ease;
	transition: all 0.6s ease;
}

@mixin transition2 {
	transition: all 0.8s ease;
	-moz-transition: all 0.8s ease;
	-webkit-transition: all 0.8s ease;
	-ms-transition: all 0.8s ease;
	-o-transition: all 0.8s ease;
}

@mixin box-shadow {
	-webkit-box-shadow: 0px 3px 5px 0px rgba(0, 31, 36, 0.3);
	-moz-box-shadow: 0px 3px 5px 0px rgba(0, 31, 36, 0.3);
	box-shadow: 0px 3px 5px 0px rgba(0, 31, 36, 0.3);
}

@mixin box-shadow2 {
	-webkit-box-shadow: 0px 16px 39px -17px rgba(133, 133, 133, 1);
	-moz-box-shadow: 0px 16px 39px -17px rgba(133, 133, 133, 1);
	box-shadow: 0px 16px 39px -17px rgba(133, 133, 133, 1);
}

@mixin shadow {
	box-shadow: 1px 1px 5px 0 rgba(1, 1, 1, .05);
	@include transition;
}

@mixin shadow-hover {
	transform: translateY(-0.25rem);
	box-shadow: 0px 2px 4px rgba(46, 41, 51, 0.08), 0px 5px 10px rgba(71, 63, 79, 0.16);
}

@mixin margin-padding0 {
	margin: 0px;
	padding: 0px;
}

@mixin ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin bglogo {
	background: url("../images/febino-logo-transparent.png") no-repeat center;
	background-size: 80% 80%;
}

//mixin-section-------

@mixin menu_sticky {
	-webkit-animation-name: menu_sticky;
	animation-name: menu_sticky;
	-webkit-animation-duration: 0.80s;
	animation-duration: 0.80s;
	-webkit-animation-timing-function: ease-out;
	animation-timing-function: ease-out;
}

@-webkit-keyframes menu_sticky {
	0% {
		margin-top: -300px;
	}

	50% {
		margin-top: -150px;
	}

	100% {
		margin-top: 0;
	}
}

@keyframes menu_sticky {
	0% {
		margin-top: -300px;
	}

	50% {
		margin-top: -150px;
	}

	100% {
		margin-top: 0;
	}
}

//End_mixin-section-------
@mixin fade-animation {
	animation-name: fade, slide;
	animation-duration: 600ms, 700ms;
	animation-fill-mode: forwards;
	animation-timing-function: linear, cubic-bezier(0.23, 1, 0.32, 1);
	animation-delay: 700ms, 0;
}

@keyframes fade {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes slide {
	0% {
		margin-top: -10px;
	}

	100% {
		margin-top: 0px;
	}
}

@mixin heading {
	font-size: $x-large;

	@media (max-width:991px) {
		font-size: 20px;
	}

	color: $color-black6;
	font-weight: normal;
}

@mixin c-dropdown {
	background-color: #fff;
	border: 1px solid $color-darkblue;
	border-radius: 10px;
	border-top-width: 8px;
	@include box-shadow2;
	@include fade-animation;

	& .d-footer {
		width: 100%;
		min-height: 10px;
		background-color: $color-lightblue3;
		display: block;
		border-radius: 0 0 10px 10px;
	}
}

@mixin btnstyle {
	padding: 5px 30px;
	font-weight: 600;
	border-radius: 20px;
	font-size: $smaller;

	&:hover {
		background-color: $theme-color  !important;
		color: #fff;
	}
}
@mixin btnstyle2 {
	padding: 7px 30px;
	font-weight: 600;
	border-radius: 20px;
	font-size: $smaller;
	margin-left:2px;
	margin-right:2px;
}