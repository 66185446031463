@media only screen and (min-width: 992px) and (max-width: 3000px) {
	.menu-overlay {
		display: none;
	}

	ul.genu-menu {
		width: auto;
		float: right;
		margin: 0;
		padding: 0 0 10px 0;
		list-style: none;

		&>li {
			width: auto;
			padding: 0;
			margin: 0;
			display: inline-block;
			text-align: center;
			position: relative;

			&>a {
				padding: 1px 10px;
				font-size: 17px;
				font-weight: 400;
				margin: 0 4px;
				font-family: $font-ubuntu;
				display: inline-block;
				color: $color-skyblue;

				@media only screen and (min-width: 1000px) and (max-width: 1300px) {
					//padding:10px;	
					font-size: 15px;
				}

				text-decoration: none;
				position: relative;
				cursor: pointer;

				&:before {
					width: 2px;
					height: 16px;
					position: absolute;
					top: 7px;

					@media only screen and (min-width: 1000px) and (max-width: 1300px) {
						top: 4px;
					}

					left: -5px;
					content: '';
					background-color: $color-lightblue5;
				}

				&:hover {
					color: $theme-color;
				}
			}

			&:first-child,
			&:last-child {
				& a {
					&:before {
						display: none;
					}
				}
			}

			&.social {
				& a {
					padding-left: 3px;
					padding-right: 3px;
					margin-left: 3px;
					margin-right: 3px;
					color: $color-darkblue  !important;
					font-size: 18px;
					margin-left: 10px;

					&>:hover {
						color: $theme-color  !important;
					}
				}

				&:hover {
					& a {
						background-color: transparent;
					}
				}
			}

			& .submenu {
				width: 500px;
				min-height: 200px;
				position: absolute;
				top: 103%;
				left: 0;
				z-index: 12;
				display: none;
				@include c-dropdown;
				overflow: hidden;

				&.m-fullwidth {
					width: 350px;

					& .r-heading {
						font-size: $large;
						font-weight: 600;
						color: $color-darkblue;
						text-align: left;
						padding: 20px 30px 10px;
					}

					& .r-download-list {
						width: 100%;
						display: flex;
						justify-content: space-between;
						padding: 5px 30px;

						& .btn-bownload {
							background-color: #ffc272;
							border: 2px dotted #6f665d;
							color: #4e352b;
							text-decoration: none;
							padding: 2px 10px;
							border-radius: 6px;
							font-size: $smaller;

							&:hover {
								background-color: $theme-color;
								color: #fff;
							}
						}
					}

					& li {
						width: 100%;
					}
				}

				& ul {
					list-style: none;
					width: 102%;
					text-align: left;
					margin: 0;
					padding: 20px 25px;
					max-height: 200px;
					overflow-y: auto;

					& li {
						width: 50%;
						float: left;
						text-align: left;
						padding: 5px 7px 5px 5px;

						& a {
							width: 100%;
							color: $color-black6;
							text-decoration: none;
							font-size: 15px;
							position: relative;
							cursor: pointer;

							&:before {
								content: "\e946";
								font-family: 'icomoon';
								position: absolute;
								top: 0px;
								left: -15px;
								font-size: $small;
								visibility: hidden;
							}

							&:hover {
								color: $color-skyblue;

								&:before {
									visibility: visible;
								}
							}

							& span {
								color: $color-skyblue;
								font-size: $smaller;
							}
						}
					}

				}

				& .view-allblock {
					width: 100%;
					display: block;
					background-color: $color-lightblue3;
					padding: 10px 25px;
					text-align: left;
					border-radius: 0 0 10px 10px;

					& .btn-viewall {
						background-color: $color-skyblue;
						@include btnstyle;
						color: #fff;
					}
				}
			}

			&:hover {
				&>a {
					background-color: $color-skyblue;
					color: #fff;
					border-radius: 6px;
				}

				& .submenu {
					display: block;
				}
			}

			& a.active {
				background-color: $color-skyblue;
				color: #fff;
				border-radius: 6px;
			}

			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5) {
				& .submenu {
					left: inherit;
					right: 0;
				}
			}
		}
	}
}

@media (max-width:992px) {
	body {
		&.open {
			overflow: hidden;
		}
	}

	.menu-overlay {
		background-color: rgba(0, 0, 0, 0.80);
		width: 100%;
		height: 100%;
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 20;

		&.open {
			display: block;
		}
	}

	ul.genu-menu {
		width: 250px;
		height: 100%;
		overflow-y: auto;
		display: block;
		margin: 0;
		padding: 0;
		background-color: $body-color;
		position: fixed;
		top: 0px;
		list-style: none;
		left: -100%;
		//left: 0;
		z-index: 22;
		padding-top: 70px;
		@include transition;

		&.open {
			left: 0;
		}

		& li {
			position: relative;
			float: left;
			margin: 0;
			padding: 0;
			width: 100%;

			& a {
				font-weight: 400;
				font-size: 16px;
				text-decoration: none;
				color: $color-black5;
				display: block;
				padding: 10px 15px;
				transition: background 0.25s;
				font-family: $font-source;
				position: relative;
				margin-bottom: 1px;

				&:hover {
					color: $theme-color;
					background-color: $color-black2;
				}

				&:focus {
					background-color: $color-black2;
					color: $theme-color;
				}
			}

			& .submenu {
				width: 100%;
				min-height: 200px;
				position: relative;
				top: 0;
				left: 0;
				z-index: 5;
				display: none;

				&.m-fullwidth {
					width: 100%;

					& .r-heading {
						font-size: $large;
						font-weight: 600;
						color: $color-darkblue;
						text-align: left;
						padding: 10px 15px;
					}

					& .r-download-list {
						width: 100%;
						display: flex;
						justify-content: space-between;
						padding: 5px 15px;

						& .btn-bownload {
							background-color: #ffc272;
							border: 2px dotted #6f665d;
							color: #4e352b;
							text-decoration: none;
							padding: 2px 10px;
							height: 26px;
							border-radius: 6px;
							font-size: $smaller;

							&:hover {
								background-color: $theme-color;
								color: #fff;
							}
						}
					}

					& li {
						width: 100%;
					}
				}

				& ul {
					list-style: none;
					width: 100%;
					text-align: left;
					margin: 0;
					padding: 10px 15px;
					max-height: 200px;
					overflow-y: auto;

					& li {
						width: 100%;
						float: left;
						text-align: left;
						padding: 0px;

						& a {
							width: 100%;
							color: $color-black6;
							text-decoration: none;
							font-size: $medium;
							position: relative;

							&:before {
								content: "\e946";
								font-family: 'icomoon';
								position: absolute;
								top: 12px;
								left: -12px;
								font-size: $small;
								visibility: hidden;
							}

							&:hover {
								color: $color-skyblue;

								&:before {
									visibility: visible;
								}
							}

							& span {
								color: $color-skyblue;
								font-size: $smaller;
							}
						}
					}

				}

				& .view-allblock {
					width: 100%;
					display: block;
					background-color: $color-lightblue3;
					padding: 10px 25px;
					text-align: left;
					border-radius: 0 0 10px 10px;

					& .btn-viewall {
						background-color: $color-skyblue;
						@include btnstyle;
						color: #fff;
					}
				}
			}

			&:hover {
				&>a {
					background-color: $color-black2;
				}

				& .submenu {
					display: block;
				}
			}


			&.social {
				//text-align: center;
				padding-left: 5px;

				& a {
					margin-left: 5px;
					margin-top: 10px;
					padding: 2px 8px;
					color: $color-darkblue;
					font-size: 18px;
					display: inline-block;

					&:hover {
						color: $theme-color;
					}
				}
			}
		}
	}
}