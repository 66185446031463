$article-color-yellow: #e2b80c;

.min-hight-article {
    
    min-height: 600px;
}

.article-menu {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #6d6b6c;
    border-radius: 7px;
    // position: sticky;
    // top: 115px;
    // z-index: 10;
    & ul {
    width: auto;
    list-style: none;
    margin: 0;
    padding: 0;
    float: left;
    & li {
    width: auto;
    float: left;
    @media only screen and (min-width:0px) and (max-width: 800px) {
    width: 50%;
    &:nth-child(odd) a {
    border-right: 1px solid #fff;
    }
    &:nth-child(even) a {
    border-right: 1px solid transparent;
    }
    }

    & a {
    padding: 10px 25px;
    display: block;
    text-decoration: none;
    border-right: 1px solid #fff;
    color: #fff;
    font-size: 15px;
    @media only screen and (min-width:1200px) and (max-width: 1400px) {
    padding: 10px 15px;
    }
    @media only screen and (min-width:800px) and (max-width: 1200px) {
    padding: 10px 15px;
    }
 
    &:hover {
    background-color: $article-color-yellow;
    }
    }
    &:first-child {
    & a {
    border-radius: 7px 0 0 7px;
    @media only screen and (min-width:0px) and (max-width: 800px) {
    border-radius: 7px 0 0 0;
    }
    }
    }
    }
    }
    & .article-search {
    flex-grow: 1;
    max-width: 100%;
    width: auto;
    & .form-control {
    background-color: $article-color-yellow;
    border-radius: 0;  
    border-color: $article-color-yellow;
    @media only screen and (min-width:0px) and (max-width: 1200px) {
    border-radius: 0 0 0 7px;
    } 
    }
    & .btn {
    background-color: $article-color-yellow;
    border-radius: 0 7px 7px 0;
    @media only screen and (min-width:0px) and (max-width: 1200px) {
    border-radius: 0 0 7px 0;
    }
    & i {
    position: relative;
    top: 1px;
    }
    }
    }
}

.a-btn {
    background-color: #ddd;
    padding: 3px 20px;
    border-radius: 20px;
    font-size: 14px;
    display: table;
}
.trending-slider {
	& .slick-slide {
		&:nth-child(3n - 1) {
            & .a-btn {
                background-color: #1a9cbe;
            }
		}
		&:nth-child(3n - 2) {
            & .a-btn {
                background-color: #31b260;
            }
		}
		&:nth-child(3n - 3) {
            & .a-btn {
                background-color: #e56fdd;
            }
		}		
	}
}
.article-heading {
    font-size: 22px;
    @media only screen and (min-width:0px) and (max-width: 800px) {
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;
    }

    color: #000;
    & span {
    color: #edae01;
    }
    & .iconround {
    background-color: #d9eeff;
    width: 45px;
    height: 45px;
    border-radius:50%;
    text-align: center;
    line-height: 45px;
    display: inline-block;
    margin-right: 10px;
    & i {
    font-size: 25px;
    color: #000;
    }
    @media only screen and (min-width:0px) and (max-width: 800px) {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-right: 5px;
    & i {
    font-size: 18px; 
    }
    }
    }
}

.list-article {
    width: 100%;
    display: inline-block;
    &:nth-child(3n - 1) {
       & .article-heading {
        & .iconround {
            background-color: #aafcd8;
        }
       }
    }
    &:nth-child(3n - 2) {
        & .article-heading {
         & .iconround {
             background-color: #c1f19a;
         }
        }
     }
     &:nth-child(3n - 3) {
        & .article-heading {
         & .iconround {
             background-color: #ffc86f;
         }
        }
     }
}
.trending-block {
    width: 100%;
    padding: 0 5px;
    & .trending-img {
    width: 100%;
    position: relative;
    min-height: 150px;
    & img {
    border-radius: 10px;
    }
    & .tr-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    border-radius: 10px;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.6558824213279062) 12%, rgba(255,255,255,0) 53%); 
    }
    & .trending-content {
    position: absolute;
    width: 100%;
    padding: 20px;
    left: 0;
    bottom: 0;
    z-index: 2;
    color: #fff;
    & h2 {
    font-size: 18px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #fff;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 99%;
    }
    & .date {
    font-size: 13px;
    }
    }
    }
}

.knowyour-block {
    width: 100%;
    display: block;
    margin-top: 15px;
    & ul {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    & li {
    width: 100%;
    display: flex;
    background-color: #f8fafc;
    border-radius: 15px;
    margin-bottom: 25px;
    @media only screen and (min-width:0px) and (max-width: 800px) {
        display: block;
    }
    & .know-img {
    width: 320px;
    position: relative;
    min-height: 150px;
    @media only screen and (min-width:0px) and (max-width: 800px) {
    width: 100%;
    }
    & img {
    border-radius: 15px 0 0 15px;
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
    @media only screen and (min-width:0px) and (max-width: 800px) {
    width: 100%;
    border-radius: 15px 15px 0 0;
    }
    }
    & .viewtag {
    width: auto;
    border-radius: 15px 0 0 0;
    background-color: #ffb639;
    padding: 3px 8px;
    color: #000;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    }
    }
    & .know-content {
    width: calc(100% - 320px);
    padding: 10px 20px;
    @media only screen and (min-width:0px) and (max-width: 800px) {
     width: 100%;
     padding: 10px;
    }
    & .kheading {
    font-size: 20px;
    @media only screen and (min-width:0px) and (max-width: 800px) {
        font-size: 18px;
    }
    margin: 0;
    padding-bottom: 10px;
    }
    
    & .pcontent {
    width: 99%;
    font-size: 16px;
    color: #373737;
    margin-top: 12px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 50px;
    }
    }
    }
    }
}

.headingblock {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.kbtn {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;  
    @media only screen and (min-width:0px) and (max-width: 800px) {
    margin-right: 5px;
    }  
}

.article-details {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    & img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    @media only screen and (min-width:0px) and (max-width: 800px) {
    height: auto;
    object-fit: inherit;
    }
    }

}
.articledetails-content {
& p {
    line-height: 22px !important;
    margin-bottom: 7px !important;
    & u {
    & a {
        margin-bottom: 6px !important;
        display: inline-block;
    }
    }
}

  & strong {
    & u {
    padding-bottom: 10px !important;
    font-size: 16px;
    }
  }
    & ol, & ul {
        margin-left: -17px !important;
        margin-top: 10px;
    & li {
    margin-bottom: 10px !important;
    }
}
}
//article-icon-----
.icon-know-your-test:before {
    content: "\e989";
}
.icon-nutrition-and-diet:before {
    content: "\e98c";
}
.icon-superfoods:before {
    content: "\e988";
}
.icon-disease-info:before {
    content: "\e986";
}
.icon-wellness-lifestyle:before {
    content: "\e98f";
}
