// Health Condition Page
.health-condition-page {
    padding-bottom: 2rem;

    h1{
        font-size: 1.5rem;
    }

    .page-title{
        font-size: 1.5rem;
    }
}
.health-condition-filter-container {
    padding: 0 3rem;
    position: relative;

    .left-btn,
    .right-btn {
        $size: 35px;
        width: $size;
        height: $size;
        color: $color-black5;
        border-radius: 50px;
        background-color: #fff;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
        border: transparent;
        position: absolute;
        top: 0;
        display: grid;
        place-content: center;

        &:disabled {
            opacity: 0.2;
            cursor: not-allowed;
        }
    }

    .left-btn {
        left: 0;
    }
    .right-btn {
        right: 0;
    }
}
.health-condition-filter {
    ul {
        display: flex;
        list-style: none;
        gap: 1rem;
        margin: 0;
        padding: 0;
        overflow-x: auto;
        padding-bottom: 0.5rem;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;

        -ms-overflow-style: none; /* IE 11 */
        scrollbar-width: none; /* Firefox 64 */
        

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
        }
    }

    li {
        a {
            display: block;
            color: inherit;
            text-decoration: none;
            border: 1px solid $color-black3;
            padding: 0.2rem 1.5rem;
            white-space: nowrap;
            border-radius: 50px;
            scroll-snap-align: start;
            cursor: pointer;
            &.active {
                background-color: $color-darkblue;
                color: #fff;
                border-color: transparent;
            }
        }
    }
}

.health-service-container {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;

    @media (max-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 420px) {
        grid-template-columns: repeat(1, 1fr);
    }

    .health-service-card {
        box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
        padding: 1rem;
        border-radius: 0.5rem;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
       

        .title {
            color: #373737;
            font-size: $medium;
            a {
                color: inherit;
                text-decoration: none;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        .other-text {
            font-size: $smaller;
            color: $color-black5;
          //  min-height: 40px;

            span {
                font-weight: 600;
            }
            &.test-organ{
                min-height: auto;
                margin-bottom: 0.2rem;

                &:last-child{
                    margin-bottom: 1rem;
                }
            }
        }
        .price-area {
            border-top: 1px solid $color-black3;
            padding-top: 1rem;
            display: flex;
            align-items: center;
        
           
        }
        .price {
            font-size: $x-large;
            color: $color-darkblue;
            font-weight: 700;
            margin: 0;
        }
        .health-service-book {
            border:1px solid $theme-color;
            background-color:transparent;
            color: $theme-color;
            text-decoration: none;
            display: inline-block;
            padding: 0.3rem 1rem;
            border-radius: 50px;
            margin-left: auto;
            font-weight: 600;

            &.active {
                border: 1px solid $theme-color;
                background-color: #fff;
                color: $color-black6;
            }
        }
    }
}
