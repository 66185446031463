@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');

$font-ubuntu: 'Ubuntu', sans-serif;
$font-source: 'Source Sans Pro', sans-serif;

//COLOR SWATCH---
//Base Colors--->
$color-black1: #F6F6F6;
$color-black2: #F2F2F2;
$color-black3: #CACACA;
$color-black4: #979797;
$color-black5: #707070;
$color-black6: #373737;

//Primary Colors--->
$color-orange: #F27D00;
$color-darkblue: #0A214F;
$color-skyblue: #61a5ff;
$color-lightoringe: #FFC574;
$color-lightpink2: #FC7070;
$color-lightgreen: #2ECCA1;

//Secondary Colors--->
$color-lightblue6: #446D95;
$color-lightblue5: #4DCDF2;
$color-lightblue4: #6688FC;
$color-lightblue3: #D7E8F8;
$color-lightblue2: #F2FAFD;
$color-lightblue1: #E7ECFE;
$color-lightpink1: #F8E6D5;

$theme-color: #F27D00;
$body-color: #fcfcfc;

$color-red: #df4747;
$color-green: #13c494;

//TYPOGRAPHY--->
$xx-small: 9px;
$x-small: 10px;
$small: 13px;
$smaller: 14px;
$medium: 16px;
$large: 18px;
$x-large: 24px;
$xx-large: 32px;