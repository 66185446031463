footer {
	background: rgb(243, 251, 253);
	background: linear-gradient(0deg, rgba(243, 251, 253, 1) 52%, rgba(255, 255, 255, 1) 77%);
	min-height: 150px;
	width: 100%;
	display: block;
	padding-top: 20px;

	& .footer-heading {
		@include heading;
		font-size: $large;
		font-weight: 600;
		color: $color-darkblue;
	}

	& ul.footermenu {
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;
		margin-bottom: 10px;

		& li {
			width: 100%;
			padding: 0;
			margin: 0;

			& a {
				display: block;
				padding: 2px;
				font-size: $smaller;
				color: $color-black5;
				font-weight: 500;
				text-decoration: none;

				&:hover {
					color: $theme-color;
				}
			}
		}
	}

	& .sec-feedback {
		border-left: 1px solid $color-black4;
		padding-left: 20px;

		@media (max-width:991px) {
			border-top: 1px solid $color-black4;
			border-left: none;
			padding-left: 15px;
		}

		& .footer-heading {
			color: $color-lightblue4;
		}

		& .co-about {
			font-size: $smaller;
			color: $color-black5;
			font-weight: 500;
		}

		& .contactlist a {
			text-decoration: none;
			font-size: $smaller;
			color: $color-black5;

			& i {
				padding-right: 5px;
			}

			&:hover {
				color: $color-lightblue4;
			}
		}
	}

	& .copyright {
		width: 100%;
		padding: 20px 15px;
		display: block;
		background-color: $color-black6;
		color: #fff;
		text-align: center;
		font-size: $smaller;

		& a {
			color: $color-lightblue4;
			text-decoration: none;
			font-weight: 600;
			padding-left: 3px;
			padding-right: 3px;
		}
	}
}


/*----LOADER-Style----*/
.genuloader {
	background-color: rgba(255, 255, 255, 0.4);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	z-index: 102;
	&.searchloader {
	right: 50px;
	width: 30px;
	height: 30px;
	left: inherit;
	& .genuloaderstyle {
	width: 35px;
	height: 35px;
	border-bottom: 2px solid transparent;
	position: absolute;
	top: 6px;
	left: inherit;
	right: 0;
	margin: inherit;
	}
	& .genuinner-search-fetchCart {
	top: 9px;
	left: 2px;
	& img {
	width: 22px;
	}
	}
	}
}

.genuloaderstyle {
	width: 60px;
	height: 60px;
	border: 3px solid $theme-color;
	border-bottom: 3px solid transparent;
	border-radius: 50%;
	position: fixed;
	top: 45%;
	left: 0;
	right: 0;
	margin: auto;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
	display: flex;
	justify-content: center;
	align-items: center;

	&.genuloadersearch {
		position: absolute;
	}
}

.genuloaderstyle .inner {
	width: 30px;
	height: 30px;
	border: 3px solid transparent;
	border-top: 3px solid $theme-color;
	border-radius: 50%;
	-webkit-animation: spinInner 1s linear infinite;
	animation: spinInner 1s linear infinite;
}

@-webkit-keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@-webkit-keyframes spinInner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(-720deg);
	}
}

@keyframes spinInner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(-720deg);
	}
}

/*---Imageloader---*/
.genuinner {
	position: fixed;
	width: 35px;
	top: 46.555%;
	left: 0;
	right: 0;
	margin: auto;

	& img {
		width: 35px;
	}

	&.genuinner-search {
		position: absolute;
		top: 47.555%;
	}
}

/*----LOADER-Style----*/


.genusocial {
	width: auto;
	display: flex;
	align-items: center;
	gap: 7px;
	& a {
	color: $color-darkblue;
	border: 1px solid currentcolor;
	font-size: 16px;
	text-align: center;
	line-height: 28px;
	text-decoration: none;
	width: 33px;
	height: 33px;
	border-radius: 50%;
	display: inline-block;
	& img {
	width: 16px;
	}
	&:hover {
	color: #fff;
	background-color: #0095d7;
	border: 1px solid #0095d7;
	& img {
	filter: brightness(0) invert(1);
	}
	}
	}
}