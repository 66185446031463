/*all-style-------------*/
body {
	margin: 0;
	padding: 0;
	font-family: $font-source;
	font-size: 16px;
	background-color: $body-color;
}

* {
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-ubuntu;
	margin-top: 10px;
	font-weight: bold;
}

p {
	font-family: $font-source;
}

.clr {
	clear: both;
}

.text-theme {
	color: $theme-color;
}

.height5 {
	height: 5px;
}

.height10 {
	height: 10px;
}

.height15 {
	height: 15px;
}

.height20 {
	height: 20px;
}

.height25 {
	height: 25px;
}

.height30 {
	height: 30px;
}

.height50 {
	height: 50px;
}

.height60 {
	height: 60px;
}

.height80 {
	height: 80px;
}

.height100 {
	height: 100px;
}

.bg-off {
	background-color: $color-lightblue2;
}

.pad0 {
	padding: 0 !important;
}

.pad3 {
	padding-left: 3px !important;
	padding-right: 3px !important;
}

.pad5 {
	padding-left: 5px !important;
	padding-right: 5px !important;
}

.pad10 {
	padding-left: 10px !important;
	padding-right: 10px !important;
}

.pad10m {
	padding-left: 10px !important;
	padding-right: 10px !important;

	@media (max-width: 767px) {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
}

.pad-m5 {
	padding-left: 5px !important;
	padding-right: 5px !important;
	margin-bottom: 10px;
}

.pad-d5 {
	padding-left: 5px;
	padding-right: 5px;

	@media (max-width: 767px) {
		padding-left: 0;
		padding-right: 0;
	}
}

.relative-block {
	position: relative;
	z-index: 1;
}

.mbottom10 {
	margin-bottom: 10px;
}

.padmar10 {
	padding-left: 10px;
	padding-right: 10px;
	margin-top: 5px;
	margin-bottom: 10px;
}

.margin-b20 {
	margin-bottom: 20px;
}

ul.list,
ol.list {
	margin: 10px 0;
	padding: 0;
	margin-bottom: 15px;

	& li {
		margin-left: 15px;
		font-family: $font-source;
		font-size: 16px;
		padding-bottom: 4px;
		color: $color-black5;

		& a {
			color: $color-black6;

			&:hover {
				color: $theme-color;
			}
		}
	}

	&.white {
		& li {
			& a {
				color: #fff;

				&:hover {
					color: $theme-color;
				}
			}
		}
	}
}

.bg-off {
	width: 100%;
	background-color: $color-lightblue2;
}

@media (max-width:991px) {
	.container {
		width: 100%;
		max-width: inherit;
	}
}

//--back-to-top---
#back-top {
	position: fixed;
	bottom: 30px;
	right: 5px;
	z-index: 1000;
	display: none;
	margin-left: 500px;
	width: 40px;
	height: 40px;
	background: #fff;
	color: $theme-color;
	text-align: center;
	cursor: pointer;
	border: 3px double $theme-color;

	&:hover {
		color: white;
		border: 1px solid $theme-color;
		;
		background: $theme-color;
	}

	& i {
		line-height: 35px;
		font-size: 12px;
		@include margin-padding0;
	}
}

//--End_back-to-top---

//Header-section---------

header {
	width: 100%;
	background-color: #fff;

	& .header-bg {
		width: 100%;
		display: inline-block;
		padding: 10px 0;
		background: rgb(243, 251, 253);
		background: linear-gradient(180deg, rgba(243, 251, 253, 1) 0%, rgba(255, 255, 255, 1) 50%);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 110;

		@media only screen and (min-width: 992px) and (max-width: 3000px) {
			@include fade-animation;
		}

		@media (max-width:992px) {
			padding-bottom: 0;
		}
	}

	& .header-height {
		width: 100%;
		display: block;
		height: 175px;
		background-color: #fff;

		@media only screen and (min-width: 550px) and (max-width: 992px) {
			height: 160px;
		}

		@media only screen and (min-width: 0px) and (max-width: 550px) {
			height: 196px;
		}
	}

	& .header-part {
		width: 100%;

		@media only screen and (min-width: 992px) and (max-width: 1920px) {
			display: block;
			// justify-content: space-between;
			// align-items: center;	
		}

		& .logo {
			width: 250px;
			float: left;

			@media only screen and (min-width: 992px) and (max-width: 1920px) {
				padding-top: 16px;
			}

			@media only screen and (min-width: 992px) and (max-width: 1300px) {
				width: 200px;
			}

			@media (max-width:991px) {
				padding-top: 0px;
				padding-bottom: 7px;
			}

			& .genulogo {
				width: 100%;
				position: relative;
				z-index: 2;

				@media (max-width:991px) {
					width: 80%;
					z-index: 23;
					display: inline-block;
				}
			}

			& .mob-menu {
				display: none;

				@media (max-width:991px) {
					display: inline-block;
					padding: 0px 5px;
					position: relative;
					top: 5px;
					cursor: pointer;
					font-size: 25px;
					@include transition;

					&.open {
						visibility: hidden;
						width: 0;
						padding: 0px;
					}

					&:hover {
						background-color: $color-lightblue2;
					}
				}
			}
		}

		@media only screen and (min-width: 992px) and (max-width: 1920px) {
			& .headerclr {
				clear: both;
			}
		}

		& .menu-part {
			width: auto;
			float: right;

			@media (max-width:991px) {
				padding-top: 5px;
				padding-bottom: 5px;
				float: right;
				width: auto;
			}

			& ul.h-toplist {
				list-style: none;
				margin: 0;
				padding: 0;
				margin-bottom: 15px;
				@include transition;

				@media (max-width:992px) {
					margin-bottom: 0;
				}

				width: auto;
				float: right;

				& li {
					display: inline-block;
					margin: 0 4px;
					padding: 0;
					vertical-align: top;

					& a {
						text-decoration: none;
					}

					& .login {
						color: $color-black4;
						padding: 0 3px;
						font-size: 14px;

						& i {
							position: relative;
							top: 1px;
						}

						&:hover {
							color: $theme-color;
						}
					}

					& %samepatern {
						background: $color-black2;
						border: 1px solid $color-black3;
						color: $color-black5;
						border-radius: 5px;
						padding: 4px 25px 4px 5px;
						font-size: 11px;
					}

					& .show {
						& .block-location {
							background-color: $color-lightblue3;
						}

						& .username,
						& .usericon {
							color: $color-darkblue  !important;
						}

					}

					& .block-location {
						@extend %samepatern;
						padding: 1px 5px;
						text-decoration: none;
						position: relative;

						& .left6 {
							padding-left: 6px;
						}

						& .isize9 {
							font-size: 9px;
						}

						&.userlogin {
							background: transparent;
							border-color: transparent;
							padding: 1px 4px;
							height: 23px;
							position: relative;

							&:after {
								content: '\e93f';
								font-family: 'icomoon';
								font-size: 9px;
								color: $color-black6;
								position: absolute;
								top: 5px;
								right: 0;
								border: none;

								@media (max-width:991px) {
									right: -8px;
									top: 2px;
								}
							}

							& .username {
								@include ellipsis;
								font-size: 13px;
								color: $color-black4;
								max-width: 120px;
								min-width: 50px;
								margin-right: 8px;
								font-weight: 600;
								display: block;

								@media (max-width:991px) {
									display: none;
								}
							}

							& .usericon {
								display: none;

								@media (max-width:991px) {
									display: inline-block;
									font-size: 18px;
									position: relative;
									top: -5px;
								}
							}

						}

						&:focus {
							box-shadow: none;
						}

						& i {
							position: relative;
							top: 1px;
							font-size: 10px;
							color: $color-black4;
						}

						&::after {
							content: '';
							border: none;
						}


					}

					& select.block-language {
						@extend %samepatern;
						padding: 0px 5px;
						height: 24px;
						cursor: pointer;
					}

					& .block-cart {
						background: transparent;
						border-color: transparent;
						display: inline-block;
						height: 30px;
						width: auto;
						padding: 0;
						color: $color-black6;
						position: relative;
						cursor: pointer;

						&:focus {
							box-shadow: none;
						}

						&::after {
							display: none;
						}

						& i {
							font-size: 30px;
							position: relative;
							top: -6px;

							@media (max-width:991px) {
								font-size: 27px;
							}
						}

						& .cartvalue {
							position: absolute;
							right: -10px;
							top: -5px;
							width: 20px;
							height: 20px;
							display: block;
							text-align: center;
							border-radius: 50%;
							background-color: $color-lightpink2;
							color: #fff;
							font-size: 10px;
							font-weight: bold;
							line-height: 20px;
							z-index: 2;
						}
					}

					& .block-name {
						color: $color-black5;
						text-decoration: none;
						font-size: 13px;
						font-weight: bold;
						text-transform: uppercase;
						position: relative;
						padding-right: 15px;

						@media (max-width:991px) {
							padding: 0 10px 0 5px;
							display: inline-block;
						}

						&:after {
							content: '\e93f';
							font-family: 'icomoon';
							font-size: 8px;
							position: absolute;
							top: -5px;
							right: 3px;

							@media (max-width:991px) {
								right: 0px;
								top: 8px;
							}
						}


					}
				}
			}
		}
	}

	& .search-section {
		width: 100%;
		padding-bottom: 20px;

		@media only screen and (min-width: 992px) and (max-width: 1920px) {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 15px;
		}

		@media (max-width:991px) {
			display: inline-block;
			padding-bottom: 0px;
			@include transition;
		}

		& .search-block {
			width: 100%;
			display: block;
			position: relative;

			& .searchBoxStyle {
				height: 45px;
				border: 1px solid $color-black4;
				border-radius: 6px !important;
				box-shadow: 0px 7px 15px 0 rgba(0, 0, 0, .16);
				color: $color-black4;
				padding-left: 20px;
			}

			& .btn-search {
				margin-top: 4px;
				height: 36px;
				padding: 0 4px;
				width: 36px;
				border-radius: 0px 20px 20px 0px !important;
				background: $color-black4;
				color: #fff;
			}
		}

		& .number-block {
			width: 300px;

			@media (max-width:991px) {
				width: 100%;
			}

			& .numberwidth {
				width: auto;
				float: right;
				display: table;
				text-align: center;

				@media only screen and (min-width: 992px) and (max-width: 3000px) {
					position: relative;
					top: -5px;
				}

				@media (max-width:991px) {
					width: 100%;
					padding-top: 15px;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}

				& span {
					font-size: 12px;
					color: $color-black5;

					@media only screen and (min-width: 992px) and (max-width: 3000px) {
						position: relative;
						top: 5px;
					}
				}

				& .jnumber {
					background: transparent;
					border-color: transparent;
					cursor: pointer;
					color: $color-skyblue;
					font-size: 15px;
					font-family: $font-ubuntu;
					font-weight: bold;
					padding: 0;

					&:focus {
						box-shadow: none;
					}

					& a {
						text-decoration: none !important;
						color: $color-skyblue;

						&:hover {
							color: $color-darkblue;
						}
					}

					& i {
						padding-right: 5px;
					}
				}
			}
		}
	}

	&.sticky {
		& .header-bg {
			@media only screen and (min-width: 992px) and (max-width: 3000px) {
				@include menu_sticky;

				& .header-part {
					& .logo {
						padding-top: 26px;
					}
				}

				& .search-section {
					width: 70%;
					float: right;
					padding-bottom: 5px;
					margin-top: 0;
					@include transition;

					& .search-block {
						& .searchBoxStyle {
							box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, .18);
						}
					}
				}

				& .menu-part {
					& ul.h-toplist {
						margin-bottom: 10px;
					}
				}

				& .headerclr {
					display: none;
				}

				& .Menu {
					display: none;
				}
			}

			& .number-block {
				display: none;
			}

			@media (max-width:991px) {
				& .search-section {
					margin-bottom: 10px;

					& .searchBoxStyle {
						box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, .18);
					}
				}
			}

			@include box-shadow;
		}

		@media (max-width:991px) {
			& .headerclr {
				clear: both;
			}
		}
	}
}

.dropdown-menu {
	&.d-menu {
		padding: 0;
		margin-top: 10px;
		margin-right: 0;
		@include c-dropdown;

		&[data-bs-popper] {
			@media (max-width:767px) {
				left: inherit;
				right: -30px;
			}
		}

		&.maxwidth2 {
			width: 200px;
		}

		&.maxwidth3 {
			width: 300px;
		}

		&.maxwidth4 {
			width: 400px;

			@media (max-width:767px) {
				max-width: inherit;
				width: 350px;
				margin-right: 10px;
			}
		}

		&.max-width04 {
			width: 350px;
		}

		&.maxwidth5 {
			max-width: 500px;
		}

		& .user-heading {
			display: flex;
			font-size: $medium;
			color: $theme-color;
			font-weight: 500;
			padding: 10px 20px 10px 25px;
			margin: 10px 0 0 0;

			& .user-name {
				@include ellipsis;
				display: inline-block;
				width: 120px;
				margin-left: 5px;
			}
		}

		& ul.user-lan-list {
			list-style: none;
			margin: 0 0 20px 0;
			padding: 0 10px;
			width: 100%;
			display: inline-block;

			& li {
				position: relative;
				width: 100%;
				padding: 4px;
				margin: 0 !important;
				width: 100%;
				text-align: left;
				position: relative;

				& a {
					cursor: pointer;
					padding: 8px 15px;
					font-size: 16px;
					font-family: $font-ubuntu;
					font-size: $smaller;
					font-weight: 500;
					line-height: 20px;
					color: $color-darkblue;
					text-decoration: none;
					display: inline-block;
					position: relative;

					&:before {
						content: "\e946";
						font-family: 'icomoon';
						position: absolute;
						top: 7px;
						left: 0px;
						font-size: $small;
						visibility: hidden;
					}

					&:hover {
						color: $color-lightblue4;

						&:before {
							visibility: visible;
						}
					}
				}

				&.active {
					& a {
						color: $theme-color;

						&:before {
							content: "\e931";
							visibility: visible;
						}
					}
				}
			}

			&.cities {
				margin: 0;

				& li {
					width: 50%;
					float: left;

				}
			}
		}

		& .language-header {
			width: 100%;
			background-color: $color-lightblue3;
			padding: 15px 20px 20px;
			display: inline-block;

			& .language-heading {
				font-weight: 600;
				font-size: $smaller;
				color: $color-darkblue;
				margin-bottom: 10px;
			}

			& .searchlocation {
				& .form-control {
					line-height: 35px;
					border-color: $color-darkblue;
					border-right: transparent;
					border-radius: 6px 0 0 6px;

					&:focus {
						box-shadow: none;
					}
				}

				& .btn-location {
					border-color: $color-darkblue;
					background-color: $color-black3;
					color: #fff;
					font-size: 20px;
					border-left: transparent;
					border-radius: 0 6px 6px 0;

					&.active-search {
						background-color: $color-darkblue;
					}
				}
			}
		}

		& .lan-heading {
			font-weight: 600;
			font-size: $smaller;
			color: $color-black6;
			padding: 20px 0px 10px 20px;
		}

		& .overflow-block {
			width: 100%;
			display: block;
			overflow: hidden;
		}

		& .cities-block-scroll {
			width: 102.7%;
			max-height: 380px;
			overflow-y: auto;
			margin-bottom: 10px;
			margin-top: 12px;
		}

		& .cities-list {
			padding: 10px 25px;
			font-size: $smaller;
			color: $color-black5;
		}

		& .cart-heading {
			display: block;
			font-size: $medium;
			color: $color-black6;
			font-weight: 500;
			padding: 5px 20px 5px 25px;
			margin: 0;

			&.summary-lab {
				font-size: $smaller;
			}

			& .summary-value {
				color: $color-black5;
				padding-left: 10px;
			}
		}

		& .summary-list {
			display: block;
			overflow: hidden;

			& ul {
				display: inline-block;
				width: 103.333%;
				max-height: 200px;
				overflow-y: auto;
				overflow-x: hidden;
				list-style: none;
				margin: 0;
				padding: 0 30px 0 20px;

				& li {
					width: 100%;
					display: flex !important;
					justify-content: space-between;
					padding: 4px 0 !important;

					& .test-name {
						font-size: $medium;
						color: $color-black5;
						width: 200px;
						@include ellipsis;
					}
                    & .poff {
						font-size: 12px;
						//margin-top: 3px;
						display: inline-block;
					}
					& .test-value {
						font-size: $medium;
						color: $color-black6;
						font-weight: 600;
						display: inline-block;
						margin-left: 8px;
					}
					& .prev-price {
						color: #979797;
						//font-size: 14px;
						display: block;
					}
					& .pricr-block {
						width: 120px;
						text-align: right;
						display: inline-block;
					}
				}
			}
		}

		& .discount-block {
			margin-top: 10px;
			border-top: 1px solid $color-black2;
			width: 100%;
			display: inline-block;
			padding: 10px 20px;

			& .discount-heading {
				color: $color-black6;
				font-size: $medium;
				text-align: center;

				& span {
					padding-left: 10px;
				}
			}

			& .coupon-search {
				padding: 10px;

				& .form-control {
					border-color: $color-lightblue4;
					border-right: transparent;
					border-radius: 6px 0 0 6px;

					&:focus {
						box-shadow: none;
					}
				}

				& .btn-location {
					border-color: $color-lightblue4;
					background-color: $color-black3;
					color: #fff;
					font-size: 12px;
					border-left: transparent;
					border-radius: 0 6px 6px 0;

					&.active-search {
						background-color: $color-lightblue4;
					}
				}
			}

			& .text-color {
				color: $color-black5;
			}
		}

		& .total-cart-value {
			margin-top: 10px;
			border-top: 1px solid $color-black2;
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 10px 20px;

			& .toral-block {
				font-size: $large;
				color: $color-black5;
				width: 200px;
			}

			& .toral-value {
				font-size: $large;
				color: $theme-color;
				font-weight: 600;
			}
		}

		& .checkout-block {
			padding: 10px 30px;
			display: block;

			& .checkout {
				@include btnstyle;
				background-color: $theme-color;
				color: #fff;
				display: block;

				&:hover {
					border-color: $theme-color;
					color: $theme-color;
					background-color: #fff !important;
				}
			}
		}

		& .call-you-back {
			width: 100%;
			display: inline-block;
			padding: 25px;
			text-align: right;

			& .call-heading {
				font-size: $large;
				color: $color-black6;
				font-weight: 500;
				margin-bottom: 10px;
			}

			& p {
				color: $color-black5;
				font-size: $medium;
				font-weight: 500;
			}

			& .form-control {
				line-height: 35px;
				border-radius: 10px;
				border-color: $color-black5;
			}
		}

		& .freecall-block {
			width: 100%;
			padding: 10px 25px;
			text-align: right;

			& .btn-freecall {
				@include btnstyle;
				background-color: $color-skyblue;
				color: #fff !important;
			}
		}
	}
}


	ul.summary-discount {
		display: inline-block;
		width: 100%;
		list-style: none;
		margin: 0;
		padding: 0;

		& li {
			width: 100%;
			display: flex !important;
			justify-content: space-between;
			padding: 4px 0 !important;

			& .test-name {
				font-size: $medium;
				color: $color-black5;
				width: 200px;
				@include ellipsis;
			}

			& .test-value {
				font-size: $medium;
				color: $color-black6;
				font-weight: 600;
			}
		}
	}

.smallertex {
	font-size: $small;
	color: $color-black4;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.heading-style {
	@include heading;
	margin-top: 5px;
	position: relative;

	&:after {
		content: '';
		background-color: $color-lightoringe;
		width: 85px;
		height: 4px;
		display: block;
		position: absolute;
		left: 0;
		bottom: -7px;
	}
}

.move-up {
	position: fixed;
	margin-right: 20px;
	bottom: 20px;
	right: 0;
	z-index: 20;
	line-height: 40px;
	-webkit-transition: 0.5s;
	transition: 0.5s;
	width: 40px;
	height: 40px;
	background: #fff;
	color: $theme-color;
	text-align: center;
	cursor: pointer;
	border: 3px double $theme-color;

	&:hover {
		color: white;
		border: 1px solid $theme-color;
		;
		background: $theme-color;
	}
}

.fuzzy {
	filter: blur(6px);
	-webkit-filter: blur(6px);
}

.modal-content {
	border-radius: 20px;

	& .close-btn {
		@include btnstyle;
		background-color: #fff;
		border: 1px solid $color-black4;
		padding-left: 20px;
		padding-right: 20px;

		&:hover {
			border-color: $theme-color;
		}
	}

	& .p-padding {
		padding-top: 20px;
		text-align: center;
	}
}

.form-control {
	&.is-valid2 {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23198754'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23198754' stroke='none'/%3e%3c/svg%3e");
	}
}