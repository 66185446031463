.search-dropdown {
	@include c-dropdown;
	width: 100%;
	position: absolute;
	top: 102%;
	left: 0;
	z-index: 10;
	display: none; //--change--

	& .search-body {
		width: 100%;
		display: inline-block;
		min-height: 40px;
		overflow: hidden;

		& .search-option {
			width: 101%;
			// display: none;//--change--
			padding: 20px 30px;
			max-height: 400px;
			overflow-y: auto;
			@include fade-animation;

			& .search-result {
				width: 100%;
				display: flex;
				justify-content: space-between;
				padding: 5px 0;

				& .showing-result {
					font-size: $large;
					color: $color-black4;
				}

				& .clear-block {
					font-size: $medium;
					color: $color-skyblue;
					cursor: pointer;
					white-space: nowrap;

					&:hover {
						color: $color-lightpink2;
					}

					& i {
						position: relative;
						top: 2px;
					}
				}
			}

			& ul.search-list {
				list-style: none;
				width: 100%;
				margin: 0;
				padding: 0;
				display: inline-block;

				& li {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 3px 0;
					border-bottom: 1px solid $color-black2;

					@media (max-width:767px) {
						display: inline-block;
					}

					&:last-child {
						border-bottom: none;
					}

					& .search-result {
						width: 60%;
						display: block;

						@media (max-width:767px) {
							width: 100%;
						}

						& a.search-name {
							display: block;
							color: $color-darkblue;
							font-size: $medium;
							text-decoration: none;
							@include ellipsis;
						}

						& .collection {
							color: $color-black5;
							font-size: $smaller;
						}
					}

					& .search-value {
						width: auto;

						@media (max-width:767px) {
							float: right;
						}

						& del {
							color: $color-black4;
							font-size: $smaller;
							margin-right: 10px;
						}

						& .mrp {
							color: $color-darkblue;
							font-size: $large;
							margin-right: 15px;
							font-weight: 600;
						}

						& .btn-booktest {
							@include btnstyle;
							border: 1px solid $theme-color;
							color: $theme-color;
							letter-spacing: 1px;
							min-width: 150px;
						}
					}

					&.selected {
						& .search-result {
							& .search-name {
								color: $theme-color;
							}
						}

						& .search-value {
							& .btn-booktest {
								border-color: transparent;
								background-color: transparent !important;
								color: $theme-color  !important;
								cursor: default;

								&::before {
									content: '\e931';
									font-family: 'icomoon';
									margin-right: 4px;
								}
							}
						}

						&.included {
							& .btn-booktest {
								font-size: 10px;
								text-align: left;
								color: #f23300 !important;
								padding-left: 5px;
								padding-right: 0px;
								width: 150px;
								position: relative;

								&::before {
									display: none;
								}
							}
						}
					}

					&:hover {
						& .search-result {
							& .search-name {
								color: $theme-color;
							}
						}

						& .search-value {
							& .btn-booktest {
								background-color: $theme-color;
								color: #fff;
							}
						}
					}
				}
			}

			& .packheading {
				width: 100%;
				display: block;
				padding: 7px 0;
				font-size: $large;
				color: $color-black5;
				border-top: 1px solid $color-black2;
			}


		}
	}

	& .searchblock {
		padding: 10px;
		display: block;
		text-align: center;
		color: $color-darkblue;
		font-size: 14px;
		font-weight: 600;
		& span {
		padding: 0 6px;
		&.textred {
		color: #f90000;
		padding-right: 0;
		}
		}
	}
}