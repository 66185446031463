@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?py9igr');
  src:  url('../fonts/icomoon.eot?py9igr#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?py9igr') format('truetype'),
    url('../fonts/icomoon.woff?py9igr') format('woff'),
    url('../fonts/icomoon.svg?py9igr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}


.icon-account:before {
  content: "\e900";
}
.icon-back-left-arrow:before {
  content: "\e901";
}
.icon-best-offers:before {
  content: "\e902";
}
.icon-back-right-arrow:before {
  content: "\e903";
}
.icon-cart-genu:before {
  content: "\e904";
}
.icon-certified-lab:before {
  content: "\e905";
}
.icon-crosshair:before {
  content: "\e906";
}
.icon-edit-1:before {
  content: "\e907";
}
.icon-edit2:before {
  content: "\e908";
}
.icon-email-2:before {
  content: "\e909";
}
.icon-envelope:before {
  content: "\e90a";
}
.icon-facebook:before {
  content: "\e90b";
}
.icon-facebook2:before {
  content: "\e90c";
}
.icon-focus-lens:before {
  content: "\e90d";
}
.icon-free-doctors:before {
  content: "\e90e";
}
.icon-garbage:before {
  content: "\e90f";
}
.icon-gift:before {
  content: "\e910";
}
.icon-google-plus:before {
  content: "\e911";
}
.icon-home1:before {
  content: "\e912";
}
.icon-home2:before {
  content: "\e913";
}
.icon-instagram:before {
  content: "\e914";
}
.icon-diabetes:before {
  content: "\e915";
}
.icon-left-arrow:before {
  content: "\e916";
}
.icon-linkedin:before {
  content: "\e917";
}
.icon-location:before {
  content: "\e918";
}
.icon-logout:before {
  content: "\e919";
}
.icon-media-play:before {
  content: "\e91a";
}
.icon-multiply:before {
  content: "\e91b";
}
.icon-my-cart:before {
  content: "\e91c";
}
.icon-notification:before {
  content: "\e91d";
}
.icon-old-phone:before {
  content: "\e91e";
}
.icon-online-report:before {
  content: "\e91f";
}
.icon-pdf-file:before {
  content: "\e920";
}
.icon-phone1:before {
  content: "\e921";
}
.icon-phone2:before {
  content: "\e922";
}
.icon-plus:before {
  content: "\e923";
}
.icon-question:before {
  content: "\e924";
}
.icon-quotation-mark:before {
  content: "\e925";
}
.icon-long-arrow-left:before {
  content: "\e926";
}
.icon-long-arrow-right:before {
  content: "\e927";
}
.icon-long-thin-left:before {
  content: "\e928";
}
.icon-long-thin-right:before {
  content: "\e929";
}
.icon-right-arrow:before {
  content: "\e92a";
}
.icon-right-quote-sign:before {
  content: "\e92b";
}
.icon-share:before {
  content: "\e92c";
}
.icon-star:before {
  content: "\e92d";
}
.icon-star-fill:before {
  content: "\e92e";
}
.icon-thick-left:before {
  content: "\e92f";
}
.icon-substract:before {
  content: "\e930";
}
.icon-tick:before {
  content: "\e931";
}
.icon-track:before {
  content: "\e932";
}
.icon-twitter:before {
  content: "\e933";
}
.icon-thick-right:before {
  content: "\e934";
}
.icon-up-arrow-1:before {
  content: "\e935";
}
.icon-warning:before {
  content: "\e936";
}
.icon-whatsapp:before {
  content: "\e937";
}
.icon-wishlist:before {
  content: "\e938";
}
.icon-wishlist-fill:before {
  content: "\e939";
}
.icon-youtube:before {
  content: "\e93a";
}
.icon-menu:before {
  content: "\e93b";
}
.icon-thin-left:before {
  content: "\e93c";
}
.icon-thin-right:before {
  content: "\e93d";
}
.icon-up-arrow:before {
  content: "\e93e";
}
.icon-up-down:before {
  content: "\e93f";
}
.icon-delivered:before {
  content: "\e940";
}
.icon-envelope-fill:before {
  content: "\e941";
}
.icon-order-as-hold:before {
  content: "\e942";
}
.icon-order-cancelled:before {
  content: "\e943";
}
.icon-order-placed:before {
  content: "\e944";
}
.icon-pending-approval:before {
  content: "\e945";
}
.icon-menu-arrow:before {
  content: "\e946";
}
.icon-arrow-down:before {
  content: "\e947";
}
.icon-arrow-left:before {
  content: "\e948";
}
.icon-arrow-right:before {
  content: "\e949";
}
.icon-arrow-up:before {
  content: "\e94a";
}
.icon-close-fill:before {
  content: "\e94b";
}
.icon-doctor-consult:before {
  content: "\e94c";
}
.icon-download-report:before {
  content: "\e94d";
}
.icon-payment-process:before {
  content: "\e94e";
}
.icon-sample-collection:before {
  content: "\e94f";
}
.icon-schedule:before {
  content: "\e950";
}
.icon-select-your-test:before {
  content: "\e951";
}
.icon-blood-sugar:before {
  content: "\e952";
}
.icon-calcium:before {
  content: "\e953";
}
.icon-corona:before {
  content: "\e954";
}
.icon-creatinine:before {
  content: "\e955";
}
.icon-fill-moji-sad:before {
  content: "\e956";
}
.icon-fill-moji-smile:before {
  content: "\e957";
}
.icon-like:before {
  content: "\e958";
}
.icon-moji-sad:before {
  content: "\e959";
}
.icon-moji-smile:before {
  content: "\e95a";
}
.icon-moji-worried:before {
  content: "\e95b";
}
.icon-search-plus:before {
  content: "\e95c";
}
.icon-solid-bottom-arrow:before {
  content: "\e95d";
}
.icon-solid-left-arrow:before {
  content: "\e95e";
}
.icon-solid-right-arrow:before {
  content: "\e95f";
}
.icon-solid-top-arrow:before {
  content: "\e960";
}
.icon-ultra-sensitive:before {
  content: "\e961";
}
.icon-urine:before {
  content: "\e962";
}
.icon-eye:before {
  content: "\e963";
}
.icon-eye-blocked:before {
  content: "\e964";
}
.icon-cart-genu2:before {
  content: "\e965";
}
.icon-dastbin-close:before {
  content: "\e966";
}
.icon-edit-box:before {
  content: "\e967";
}
.icon-edit-line:before {
  content: "\e968";
}
.icon-equipment:before {
  content: "\e969";
}
.icon-machine-data:before {
  content: "\e96a";
}
.icon-qr-code:before {
  content: "\e96b";
}
.icon-quality-control:before {
  content: "\e96c";
}
.icon-report-download:before {
  content: "\e96d";
}
.icon-nabl:before {
  content: "\e96e";
}
.icon-close:before {
  content: "\e96f";
}
.icon-drop-down:before {
  content: "\e970";
}
.icon-filter-clear:before {
  content: "\e971";
}
.icon-filter-fill:before {
  content: "\e972";
}
.icon-filter-line:before {
  content: "\e973";
}
.icon-grid-view:before {
  content: "\e974";
}
.icon-list-view:before {
  content: "\e975";
}
.icon-offer-bg:before {
  content: "\e976";
}
.icon-offer-bg2:before {
  content: "\e977";
}
.icon-top-offers:before {
  content: "\e978";
}
.icon-mobile:before {
  content: "\e979";
}
.icon-refresh:before {
  content: "\e97a";
}
.icon-reload:before {
  content: "\e97b";
}
.icon-save:before {
  content: "\e97c";
}
.icon-absolutely:before {
  content: "\e97d";
}
.icon-unlikely:before {
  content: "\e97e";
}
.icon-likely:before {
  content: "\e97f";
}
.icon-arrow-round-bottom:before {
  content: "\e980";
}
.icon-arrow-round-left:before {
  content: "\e981";
}
.icon-arrow-round-right:before {
  content: "\e982";
}
.icon-arrow-round-top:before {
  content: "\e983";
}
.icon-preparation:before {
  content: "\e984";
}
.icon-smile-ordersuccess:before {
  content: "\e985";
}
.icon-disease:before {
  content: "\e986";
}
.icon-expert:before {
  content: "\e987";
}
.icon-foods:before {
  content: "\e988";
}
.icon-know-test:before {
  content: "\e989";
}
.icon-lab:before {
  content: "\e98a";
}
.icon-lab_online_report:before {
  content: "\e98b";
}
.icon-nutrition:before {
  content: "\e98c";
}
.icon-read:before {
  content: "\e98d";
}
.icon-tags:before {
  content: "\e98e";
}
.icon-wellness:before {
  content: "\e98f";
}
.icon-womens-health:before {
  content: "\e990";
}
.icon-reschedule:before {
  content: "\e991";
}
.icon-sandglass:before {
  content: "\e992";
}
.icon-export:before {
  content: "\e993";
}